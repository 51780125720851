import { Component, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { UserService } from '../../../../../_shared/shared/helper-service/user.service';
import { AuthService } from '../../../../../_shared/web-service/auth.service';
import { route, RouterService } from '../../../../../_shared/shared/helper-service/router.service';
import { LoggerHelperService } from 'projects/_shared/shared/helper-service/logger-helper.service';
import { SpinnerHelperService } from 'projects/_shared/shared/helper-service/spinner.service';
import { IndicatorActions } from 'projects/_shared/shared/store/reducers/indicator.reducer';
import { Subscription } from 'rxjs';
import { IAppState } from 'projects/_shared/shared/store/app.store';
import { Store } from '@ngrx/store';



@Component({
  selector: 'app-terms-accept',
  templateUrl: "./terms-accept.component.html",
  styleUrls: ['./terms-accept.component.scss']
})

export class TermsAcceptComponent implements OnInit {

  termsContentHtml !: SafeHtml;
  termsLoadFailed = false;
  dataLoaded: boolean = false;
  dataLoadedSubscription!: Subscription;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private routerService: RouterService,
    private spinnerService: SpinnerHelperService,
    private sanitizer: DomSanitizer,
    private loggerHelperService: LoggerHelperService,
    private store: Store<IAppState>
  ) {
    this.dataLoadedSubscription = this.store.select<boolean | undefined>(state => state.DataLoaded)
    .subscribe(loaded => this.dataLoaded = loaded!);
  }

  ngOnInit() {
    this.spinnerService.setDataLoaded(false);
    this.authService.getTermsHtml().subscribe(data => {
      this.displayTermsCondition(data.success, data.htmlDocument);
    },
      data => {
        this.displayTermsCondition(false, "");
      });

  }
  ngOnDestroy() {
  }

  displayTermsCondition(success: boolean, terms: string) {
    this.spinnerService.setDataLoaded(true);
    if (success) {
      this.termsContentHtml = this.sanitizer.bypassSecurityTrustHtml(terms);
    }
    this.termsLoadFailed = !success;
  }

  cancelTerms() {
    this.routerService.navigateToSSOLogout();
  }

  acceptTerms() {
    let gcid = this.userService.getGcid();
    this.spinnerService.setDataLoaded(false);
    this.authService.acceptTermsAndConditions(gcid)
      .subscribe(response => { this.postAcceptTerms(response); });
  }

  private postAcceptTerms(response: boolean) {
    this.spinnerService.setDataLoaded(true);
    if (response) {
      this.userService.setTermsAccepted(response);
      this.routerService.navigateToBaseUrl();
    } else {
      this.loggerHelperService.logCritical(`Error occurred in TermsAcceptComponent, Gcid: ${this.userService.getGcid()}  , authService.acceptTermsAndConditions().`);
      this.cancelTerms();
    }
  }
  termsView(){
    window.open(route.termsview);
  }

}
