import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { route } from 'projects/_shared/shared/helper-service/router.service';
import { SsoComponent, SsoLogoutComponent } from 'customerdigital-service-lib';
import { TermsAcceptedGuard } from 'projects/_shared/_guards/terms-accepted.guard';
import { HomeResolve } from 'projects/_shared/components/home/home.resolve';
import { PathNotFoundModule } from '../app/path-not-found/path-not-found.module';
import { AuthGuard } from 'projects/_shared/_guards/auth.guard';

const routes: Routes = [
  { path: "", pathMatch: 'full', redirectTo: route.home },
  {
    path: route.home, loadChildren: () => import('../../../_shared/components/home/home.module').then(m => m.HomeModule),
    canActivate: [TermsAcceptedGuard, AuthGuard],
    resolve: {
      void: HomeResolve
    }
  },
  {
    path: route.sso, component: SsoComponent
  },
  {
    path: route.ssoLogout, component: SsoLogoutComponent
  },
  {
    path: route.faq, loadChildren: () => import('../app/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: "terms", loadChildren: () => import('../app/terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: "**", loadChildren:() => PathNotFoundModule
  }
];

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled'
};


@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
