
<div *ngIf="dataLoaded" class="mini-terms">
    <div class="terms-wrapper" *ngIf="termsLoadFailed; else termsLoadSuccess" >
        <div class="mini-terms terms-accept-error-container"> 
            <h1> {{ 'terms.accept.title' | translate}} </h1>
                <fs-text class="mini-terms--error" type="error" title="{{ 'terms.accept.title' | translate}}">{{'global.technical-error' | translate}}</fs-text>            
        </div>
    </div>
    <ng-template #termsLoadSuccess>
        <app-terms headTitle="{{ 'terms.accept.title' | translate}}" checkboxLabel="{{ 'terms.accept.lbl-agree' | translate}}" primaryBtnText="{{ 'terms.accept.btn-continue' | translate}}"    secondaryBtnText="{{ 'terms.accept.btn-close' | translate}}" [termsContentHtml]="termsContentHtml" checkboxLink="{{ 'terms.accept.lnk-online-services-agreement' | translate}}" class="terms-accept"(onCancel)="cancelTerms()" (onContinue)="acceptTerms()" (onTermsAccepted)="termsView()" errorMessage="{{ 'global.technical-error' | translate}}" checkboxErrorMessage="{{'validation.error.accepted-required'  | translate}}"> </app-terms>
    </ng-template>
</div>