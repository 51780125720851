import { Component, OnInit } from '@angular/core';
import { RouterService } from 'projects/_shared/shared/helper-service/router.service';


@Component({
  selector: 'app-path-not-found',
  templateUrl: './path-not-found.component.html',
  styleUrls: ['./path-not-found.component.scss']
})
export class PathNotFoundComponent implements OnInit {

  constructor(private routerService: RouterService) { }

  ngOnInit(): void {
  }

  navigateToBaseURL(): void {
    this.routerService.navigateToBaseUrl();
  }

}
