<div class="container">
    <fs-topnav 
    *ngIf="mainHeader"
    [logo]="mainHeader.logo"
    [ariaLabelText]="mainHeader.ariaLabelText"
    [isLoggedIn]="isAuthenticated"
    [menuTitle]="mainHeader.menuTitle"
    [headerTitle]="mainHeader.headerTitle"
    [skipToMainLinkText]="mainHeader.skipToMainLinkText"
    [skipToMainLinkUrl]="skipToMainUrl"
    [topNavMenuItems]="mainHeader.topNavMenuItems"
    (onMenuItemSelect)="onMenuItemSelect($event)" (logoLinkClick)="logoLinkClick()">
    </fs-topnav>
    <div class="content-container" id="main">
        <router-outlet></router-outlet>
        <fs-progress-bar *ngIf="!dataLoaded" [text]="'spinner.text' | translate"></fs-progress-bar>
    </div>
    <div class="mini-footer">
        <fs-auth-hub-footer [copyRightText]="copyRightText" [copyRightTextMobile]="copyRightTextMobile"
            [footerMenuItems]="footerMenuItems" (onClick)="onFooterMenuItemSelect($event)">
        </fs-auth-hub-footer>
    </div>
</div>