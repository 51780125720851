import { EnvironmentConfig } from "projects/_shared/models/configuration/environment.config";
import { Environment } from "projects/_shared/models/enums";
import { Tile } from 'customerdigital-ui-lib';

export const environment: EnvironmentConfig = {
  ENV: Environment.DEV,
  PRODUCTION: true,
  LOGIN_URL: "https://login-i.bmwusa.com/oneid/#/login",
  LOGOUT_URL: "https://login-i.bmwusa.com/oneid/#/logout",
  SSO_REDIRECT_URL: "https://devmymini.miniusa.com/sso",
  GATEWAY_API_BASE_URL: "https://devmymini.miniusa.com",
  CONSOLE_LOGGING_ENABLED: true,
  APP_LOGGING_ENABLED: true,
  BASE_URL: "https://devmymini.miniusa.com/",
  PROFILE_URL: "https://login-i.bmwusa.com/oneid/#/profile",
  CLIENT_CONFIG: [
    {
      autoredirect: true,
      loginUri: "https://test-mygarage.miniusa.com",
      tileClass: "my-garage-mini",
      openInNewTab: false,
      creditCardTile: "",
      ssoLoginUri: "",
      cardHolderSsoEnabled: false,
      samlBase64: "",
      clientId: "0ff35533-1794-499b-90bb-1a80ddc24e20",
      redirectUri: "https://test-mygarage.miniusa.com/code-receiver.html",
      title: new Tile("")
    }
  ],
  IDLE_SESSION: 2,
  TIME_OUT_SESSION: 1,
  GLOBAL_NAV_DOMAIN_URL: "",
  PRIVACY_POLICY_URL: "https://www.miniusa.com/footer-navigation/privacy-legal.html",
  MY_DOMAIN_URL: "https://www.miniusa.com/",
  CONTACT_US: "https://www.miniusa.com/footer-navigation/contact.html",
  DEVICE_TOKEN_URL: "",
  MYPROFILE_URL: "https://devmymini.miniusa.com/profile"
};
