import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PathNotFoundComponent } from './path-not-found.component';

const pathNotFoundRoutes: Routes = [
  { path: '', component: PathNotFoundComponent},
];

@NgModule({
  imports: [
    RouterModule.forChild(pathNotFoundRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class PathNotFoundRoutingModule { }
