<div class="page-path-not-found">
  <div class="max-width">
    <img src="../../../assets/images/mini-404.jpg" alt="{{'page-not-found.img-alt' | translate}}" />
    <h1>
      {{'page-not-found.title' | translate}}
    </h1>
    <p>{{'page-not-found.paragraph' | translate}}</p>
    <a class="return-home-button" id="goToHomeLink" (click)="navigateToBaseURL()">{{'page-not-found.btn-text' | translate}}</a>
  </div>
</div>
