export enum HeaderMenuItemType {
  MyGarage = "My Garage",
  EditUsername = "Edit Username",
  Logout = "Logout"
}

export enum FooterMenuItemType {
  PrivacyAndLegal = "Privacy & Legal",
  TermsAndConditions = "Terms and Conditions",
  FAQ = "FAQ",
  ContactUs = "Contact Us",
  MINIUSA = "MINIUSA.com"
}


