import { Component, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { SpinnerHelperService } from 'projects/_shared/shared/helper-service/spinner.service';
import { IAppState } from 'projects/_shared/shared/store/app.store';
import { IndicatorActions } from 'projects/_shared/shared/store/reducers/indicator.reducer';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../../_shared/web-service/auth.service';


@Component({
  selector: 'app-terms-view',
  templateUrl: "./terms-view.component.html",
  styleUrls: ['./terms-view.component.scss']
})

export class TermsViewComponent implements OnInit {
  termsLoadFailed = false;
  dataLoaded: boolean = false;
  dataLoadedSubscription!: Subscription;
  htmlContent !: SafeHtml;


  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthService,
    private spinnerService: SpinnerHelperService,
    private store: Store<IAppState>
  ) {
    this.dataLoadedSubscription = this.store.select<boolean | undefined>(state => state.DataLoaded)
      .subscribe(loaded => this.dataLoaded = loaded!);
  }

  ngOnInit() {
    this.spinnerService.setDataLoaded(false);
    this.authService.getTermsHtml().subscribe(data => {
      this.displayTermsCondition(data.success, data.htmlDocument);
    },
      data => {
        this.displayTermsCondition(false, "");
      });

  }

  closeTerms() {
    window.close();
  }

  displayTermsCondition(success: boolean, terms: string) {
    this.spinnerService.setDataLoaded(true);
    if (success) {
      this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(terms);
    }
    this.termsLoadFailed = !success;
  }
}
