import { NgModule } from '@angular/core';
import { PathNotFoundComponent } from './path-not-found.component';
import { PathNotFoundRoutingModule } from './path-not-found-rotuing.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [PathNotFoundComponent],
  imports: [
    CommonModule, PathNotFoundRoutingModule, TranslateModule
  ]
})

export class PathNotFoundModule { }
